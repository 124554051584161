import "./index.css"

import { graphql } from "gatsby"
import React from "react"

import { Trans } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby-plugin-react-i18next"
import { Parallax } from "react-scroll-parallax"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PatternLateral from "../../components/patternLateral/PatternLateral"

import LogoLuz from "../../svg/portfolio/logoLuz.svg"
import LogoTHU from "../../svg/portfolio/logoTHU.svg"
import LogoTribu from "../../svg/portfolio/logoTribu.svg"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout territory={"art"}>
      <Seo title={t("Works")} />

      <PatternLateral territory="art" />
      <h2 className="sectionTitle">
        <Trans>Our works</Trans>
      </h2>

      <div className="portfolioBillboard">
        <Link to="/art/luz">
          <div className="billboardPoster">
            <StaticImage
              src="../../images/portfolio/luz.jpg"
              alt={t("Poster of Luz, the chaman girl film")}
              className="billboardImg"
            />
            <Parallax speed={3} className="billboardLogo">
              <LogoLuz />
            </Parallax>
          </div>

          <span className="billboardDate">
            <Trans>Coming soon</Trans>
          </span>
          <span className="billboardType">
            <Trans>Feature film</Trans>
          </span>
        </Link>

        <Link to="/art/wish-dragon">
          <div className="billboardPoster">
            <StaticImage
              src="../../images/portfolio/wd.jpg"
              alt={t("Poster of the film Wish Dragon")}
              className="billboardImg"
            />
            <Parallax speed={3} className="billboardLogo wishDragonLogo">
              <StaticImage src="../../images/portfolio/wd-logo.png" alt={""} />
            </Parallax>
          </div>
          <span className="billboardDate">2021</span>
          <span className="billboardType">
            <Trans>Feature film service</Trans>
          </span>
        </Link>

        <Link to="/art/red-shoes-seven-dwarfs">
          <div className="billboardPoster">
            <StaticImage
              src="../../images/portfolio/rs7d.jpg"
              alt={t("Poster of Red Shoes and the Seven Dwarfs film")}
              className="billboardImg"
            />
            <Parallax speed={3} className="billboardLogo rs7dLogo">
              <StaticImage
                src="../../images/portfolio/rs7d-logo.png"
                alt={""}
              />
            </Parallax>
          </div>
          <span className="billboardDate">2019</span>
          <span className="billboardType">
            <Trans>Feature film service</Trans>
          </span>
        </Link>

        <Link to="/art/thu-2018">
          <div className="billboardPoster">
            <StaticImage
              src="../../images/portfolio/thu2018.jpg"
              alt={t("Poster of the THU 2018 event")}
              className="billboardImg"
            />
            <Parallax speed={3} className="billboardLogo THUlogoBlack">
              <LogoTHU />
            </Parallax>
          </div>
          <span className="billboardDate">2018</span>
          <span className="billboardType">
            <Trans>Opening Ceremony Video</Trans>
          </span>
        </Link>

        <Link to="/art/thu-2017">
          <div className="billboardPoster">
            <StaticImage
              src="../../images/portfolio/thu2017.jpg"
              alt={t("Poster of the THU 2017 event")}
              className="billboardImg"
            />
            <Parallax speed={3} className="billboardLogo">
              <LogoTHU />
            </Parallax>
          </div>
          <span className="billboardDate">2017</span>
          <span className="billboardType">
            <Trans>Opening Ceremony Video</Trans>
          </span>
        </Link>

        <Link to="/art/studioreel">
          <div className="billboardPoster">
            <StaticImage
              src="../../images/portfolio/reelLTA.jpg"
              alt={t("Poster of La Tribu Animation Reel")}
              className="billboardImg"
            />
            <Parallax speed={6} className="billboardLogo tribuLogo">
              <LogoTribu />
            </Parallax>
          </div>
          <span className="billboardDate">
            <Trans>2017 to 2020</Trans>
          </span>
          <span className="billboardType">
            <Trans>Studio Reel</Trans>
          </span>
        </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
